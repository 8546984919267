<template>
    <v-card flat :color="connectionStatus?$store.getters.getColorPalette().successColor:$store.getters.getColorPalette().alertColor" class="" :dark="$store.getters.getColorPalette().isDark">
        
    <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-container>
            <v-card-title>{{hub.hub_name?hub.hub_name:hub.hub_id}} <v-progress-circular
      indeterminate
      v-if="loading"
      color="primary"
    ></v-progress-circular> <v-spacer></v-spacer><span v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess','betaaccess'])"><v-btn
      class="mx-1"
      @click="showDialog = true"
      v-if="!loading"
      icon
      
      
    >
     <DeleteConfirmation  v-on:confirm="deleteHub()"  title="Delete Confirmation"  description="Are you sure you want to delete this?">
      <v-icon small  :dark="$store.getters.getColorPalette().isDark" color="pink">
        mdi-delete
      </v-icon>
      </DeleteConfirmation>
     
    </v-btn></span></v-card-title>
        <v-divider></v-divider>
        <v-hover v-slot="{ hover }">
        <v-card-subtitle >
        <v-row>
            <v-col>
                <strong >{{$store.getters.getTextMap().id}}:</strong> {{hubID}}
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <strong >{{$store.getters.getTextMap().ip}}:</strong> {{hub.ip}}
            </v-col>
        </v-row>
        <v-overlay
          absolute
          :value="hover"
        >
        <v-row justify="center" align="center" align-content="center">
            <v-col justify="center" align="stretch" align-content="center">
          <v-btn
             :color="$store.getters.getColorPalette().submitbtnColor"
            @click="dialog = true"
          >
          {{$store.getters.getTextMap().edit_wifi}}
            <v-icon>mdi-wifi</v-icon>
          </v-btn>
            </v-col>
        </v-row>
        <v-row v-if="$store.state.user && $store.getters.getAccessByLevels(['engineeraccess','hub'])" justify="center" align="center" align-content="center">
          <v-col justify="center" align="center" align-content="center">
            <v-btn
            @click="detailDialog = true">
            {{$store.getters.getTextMap().details}}
            </v-btn>
            
          </v-col>
        </v-row>
        </v-overlay>
        </v-card-subtitle>
        </v-hover>
        </v-container>
        <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
    <v-card :style="{background: $store.getters.getColorPalette().backgroundColorCode}">
    <v-toolbar
        :dark="$store.getters.getColorPalette().isDark"
            :color="$store.getters.getColorPalette().foregroundColorCode"
        >
          <v-btn
            icon
        :dark="$store.getters.getColorPalette().isDark"
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>  {{$store.getters.getTextMap().hub}} - {{hub.name?hub.name:hub.hub_id}} </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
            :dark="$store.getters.getColorPalette().isDark"
              text
              @click="dialog = false"
            >
            {{$store.getters.getTextMap().close}}
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-container>
            
        <v-row>
            <v-col>
       <UpdateHubWifi :hub="hub" v-on:close="dialog=false" />
            </v-col>
        </v-row>
        <v-divider></v-divider>
        
        </v-container>
    </v-card>
        </v-dialog>
        <v-dialog
      v-model="detailDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
    <v-card  :style="{background: $store.getters.getColorPalette().backgroundColorCode}">
    <v-toolbar
         :dark="$store.getters.getColorPalette().isDark"
           :color="$store.getters.getColorPalette().foregroundColorCode"
        >
          <v-btn
            icon
          :dark="$store.getters.getColorPalette().isDark"
            @click="detailDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>  {{$store.getters.getTextMap().hub}}- {{hub.name?hub.name:hub.hub_id}} </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
             :dark="$store.getters.getColorPalette().isDark"
              text
              @click="detailDialog = false"
            >
            {{$store.getters.getTextMap().close}}
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-container>
            
        
        <v-row>
          <v-col self-align="center">
            <span :style="{color:$store.getters.getColorPalette().accentCode}">{{$store.getters.getTextMap().connectivity_status}}:</span>
            
              <span :style="{color:$store.getters.getColorPalette().accentCode}"><v-icon small :color="connectionStatus?'green':'red'"  >{{connectionStatus?'mdi-check-circle':'mdi-alert-octagon'}}</v-icon></span>
          
            </v-col>
        
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
      
          <v-col   :style="{color:$store.getters.getColorPalette().accentCode}">
            <span class="text-h6">{{$store.getters.getTextMap().connected_to}}: </span>
            <span class="text-subtitle">{{connectionStatus?hub.connected_ssid:'unknown'}}</span>
          </v-col>
          <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
          <v-col    :style="{color:$store.getters.getColorPalette().accentCode}">
            <span class="text-h6">{{$store.getters.getTextMap().software_version}}:</span>{{hubHealth?hubHealth.version:'unknown'}}
          </v-col>
          <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
          <v-col    :style="{color:$store.getters.getColorPalette().accentCode}">
            <span class="text-h6">{{$store.getters.getTextMap().mac_address}}:</span>{{hubHealth?hubHealth.mac_id:'unknown'}}
          </v-col>
          </v-row>
          <v-row>
            <v-col align-self="center"   :style="{color:$store.getters.getColorPalette().accentCode}"><span class="text-subtitle-2 ">{{$store.getters.getTextMap().opc_client_enabled}}: </span>
            <span :style="{color:$store.getters.getColorPalette().accentCode}">{{hub.opc_client?'Yes':'No'}}</span><v-icon color="green" small @click="toggleOPC=!toggleOPC">mdi-pencil</v-icon></v-col>
            <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-col v-if="hub.opc_client" :style="{color:$store.getters.getColorPalette().accentCode}"><span class="text-subtitle-2">{{$store.getters.getTextMap().OPC_server_URL}}: </span>
            <span :style="{color:$store.getters.getColorPalette().accentCode}">{{hub.opc_url}}</span></v-col>
          </v-row>
          <v-row v-if="toggleOPC">
            <v-col>
              <UpdateHubOPC :hub="hub" v-on:close="toggleOPC=false"/>
            </v-col>
          </v-row>
          <v-divider></v-divider> 
          <v-row   :style="{color:$store.getters.getColorPalette().accentCode}">
            <v-col align-self="center"><span class="text-subtitle-2" >{{$store.getters.getTextMap().MQTT_bridge_enabled}}: </span>
            <span :style="{color:$store.getters.getColorPalette().accentCode}">{{hub.mqtt_bridge?'Yes':'No'}}</span>
            <v-icon color="green" small @click="toggleMQTT=!toggleMQTT">mdi-pencil</v-icon>
            </v-col>
            <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <v-col align-self="center"><span class="text-subtitle-2" >{{$store.getters.getTextMap().hub_specification}}</span>
            <v-icon color="green" small @click="toggleHubSpec=!toggleHubSpec">mdi-pencil</v-icon>
            </v-col>
            <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <v-col align-self="center"><span class="text-subtitle-2" >{{$store.getters.getTextMap().hub_details}}</span>
            <v-icon color="green" small @click="toggleHubDetails=!toggleHubDetails">mdi-pencil</v-icon>
            </v-col>
          </v-row>
          <v-row v-if="toggleMQTT" >
            <v-col cols="12">
              <UpdateHubMQTTBridge :hub="hub" v-on:close="toggleMQTT=false" />
            </v-col>
          </v-row>
          <v-row v-if="toggleHubSpec" >
            <v-col cols="12">
              <UpdateHubSpec :hub="hub" v-on:close="toggleHubSpec=false" />
            </v-col>
          </v-row>
          <v-row v-if="toggleHubDetails" >
            <v-col cols="12">
              <UpdateHubDetails :hub="hub" v-on:close="toggleHubDetails=false" />
            </v-col>
          </v-row>
          <v-row    :style="{color:$store.getters.getColorPalette().accentCode}">
            <v-col align-self="center"><span class="text-subtitle-2">{{$store.getters.getTextMap().auto_restart}}: </span>
            <span :style="{color:$store.getters.getColorPalette().accentCode}">{{hub.restart?'Enabled':'Disabled'}}</span></v-col>
            <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-col ><span class="text-subtitle-2">{{$store.getters.getTextMap().restart_schedule}}: </span>
            <span v-if="hub.restart" :color="$store.getters.getColorPalette().accentCode">{{restartSchedule}}</span>
            <span v-else>-</span></v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row no-gutters justify="end">
            <v-col v-if="hubHealth"><v-btn color="red" small text @click="restart">{{$store.getters.getTextMap().restart_device}}</v-btn></v-col>
            <v-col align-self="center"><v-btn color="blue" small text @click="showSchedule=!showSchedule">{{$store.getters.getTextMap().set_up_restart_time}}</v-btn></v-col>
            
            <v-col align-self="center" ><v-btn color="green" small text @click="showConnectivityTable=!showConnectivityTable">{{$store.getters.getTextMap().connectivity_logs}}</v-btn></v-col>
          </v-row>
          <v-row v-if="showSchedule" >
            <v-col>
              <UpdateHubRestartSchedule :hub="hub" v-on:close="update"/>
            </v-col>
          </v-row>
          
          <v-row v-if="showConnectivityTable">
            <v-col>
              <HubInternetConnectivityTable :hub_id="hub.hub_id" />
            </v-col>
          </v-row>
          <v-row>
          <v-col    :style="{color:$store.getters.getColorPalette().accentCode}">
            <span class="text-subtitle-2">{{$store.getters.getTextMap().internal_cache_size}}</span><br>
            {{$store.getters.getTextMap().default}}:{{hubHealth?hubHealth.dbSize:'-'}} {{$store.getters.getTextMap().kb}}<br>
            {{$store.getters.getTextMap().configs}}:{{hubHealth?hubHealth.cdbSize:'-'}}  {{$store.getters.getTextMap().kb}}<br>
            {{$store.getters.getTextMap().processed}}:{{hubHealth?hubHealth.pdbSize:'-'}}  {{$store.getters.getTextMap().kb}}
          </v-col>
          <v-divider
          class="mx-4"
          inset
          vertical
          :dark="$store.getters.getColorPalette().isDark"
        ></v-divider>
          <v-col align-self="center"   :style="{color:$store.getters.getColorPalette().accentCode}">
            <span class="text-subtitle-2">{{$store.getters.getTextMap().space_available}}: </span>{{hubHealth?Math.round(hubHealth.iotDumpFree/1024):'-'}}{{$store.getters.getTextMap().mb}}
          </v-col>
          <v-divider
          class="mx-4"
          v-if="$store.state.user && $store.getters.getAccessByLevels(['engineeraccess']) "
          inset
          vertical
          :dark="$store.getters.getColorPalette().isDark"
        ></v-divider>
          <v-col align-self="center" v-if="$store.state.user && $store.getters.getAccessByLevels(['engineeraccess']) " :style="{color:$store.getters.getColorPalette().accentCode}">
            <span class="text-subtitle-2">{{$store.getters.getTextMap().last_ping}}: </span>{{lastKnownPing}}
          </v-col>
        </v-row>
        <v-divider :dark="$store.getters.getColorPalette().isDark"></v-divider> 

          <v-row v-if="$store.state.user && $store.getters.getAccessByLevels(['betaaccess']) " >
          <v-col >
            
             <v-data-table 
             :color="$store.getters.getColorPalette().cardColor"
      :headers="headers"
      :items="relays"
      item-key="key"
      :search="search"
      :single-expand="true"
      :loading="loading"
    loading-text="Loading... Please wait"
    :dark="$store.getters.getColorPalette().isDark"
    >
    <template v-slot:top>
      <v-toolbar
        flat
         :dark="$store.getters.getColorPalette().isDark"
      >
        <v-toolbar-title>{{$store.getters.getTextMap().relays}}</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
       
      </v-toolbar>
          </template>
          <template v-slot:item.state="{item}">
            {{item.state}} 
            <v-btn small outlined color="pink" @click="toggleRelay(item)">{{$store.getters.getTextMap().toggle}}</v-btn>
          </template>
          
    </v-data-table>
          </v-col>
        </v-row>
        <v-row v-if="$store.state.user && $store.getters.getAccessByLevels(['betaaccess']) " >
          <v-col>{{relayHealth}}</v-col>
        </v-row>

        
          <v-card  :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode" v-if="$store.state.user && $store.getters.getAccessByLevels(['engineeraccess']) ">
            <v-card-title>{{$store.getters.getTextMap().configured_creds}}</v-card-title>
            <v-divider></v-divider>
            <v-card-subtitle>
              <v-row>
            <v-col>
              <span><strong>{{$store.getters.getTextMap().SSID}}: </strong></span>
              <span>{{ssid}}</span>
            </v-col>
            <v-col>
              <span><strong>{{$store.getters.getTextMap().psk}}: </strong></span>
              <span>{{psk}}</span>
            </v-col>
        </v-row>
        
            </v-card-subtitle>
          </v-card>
        
        
        </v-container>
    </v-card>
        </v-dialog>

    </v-card>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import InfoAlert from '@/components/InfoAlert'
import DeleteConfirmation from '@/components/DeleteConfirmation'
import UpdateHubWifi from '@/components/UpdateHubWifi'
import HubInternetConnectivityTable from '@/components/lists/HubInternetConnectivityTable'
import UpdateHubOPC from '@/components/crud_components/hub/UpdateHubOPC'
import UpdateHubRestartSchedule from '@/components/crud_components/hub/UpdateHubRestartSchedule'
import UpdateHubMQTTBridge from '@/components/crud_components/hub/UpdateHubMQTTBridge'
import UpdateHubSpec from '@/components/crud_components/hub/UpdateHubSpec'
import UpdateHubDetails from '@/components/crud_components/hub/UpdateHubDetails'
import {io} from 'socket.io-client'
export default {
    name:'Hub',
    props:['hub'],
    components:{
        UpdateHubWifi,
        HubInternetConnectivityTable,
        UpdateHubOPC,
        InfoAlert,
        UpdateHubRestartSchedule,
        UpdateHubMQTTBridge,
        DeleteConfirmation,
        UpdateHubSpec,
        UpdateHubDetails
    },
    created(){
    //this.socket=io('https://localhost:80');
    this.stream=io(this.$store.state.streamApi,{withCredentials: true, auth:{token:this.$store.state.jwt}})
  },
  beforeDestroy() {
    //this.socket.close()
    //console.log('before destroy')
    this.stream.close()
  },
  mounted(){
      //this.streamData=this.currentData
      this.stream.on("connect_error", (err) => {
  console.log(err.message); // prints the message associated with the error
});
      this.stream.on('error',data=>{
      console.log(data)
      
    })
    this.stream.on('hubHealth',data=>{
      // console.log(data)
      if(data.constructor==String){
        data=JSON.parse(data)
      }
      
      this.hubHealth=data
      //console.log(data)
      
    })
    this.stream.emit('gethubHealth',this.hub.hub_id)
    },
    data(){
        return {
          showDialog:false,
          showConnectivityTable:false,
            showDismissibleAlert:false,
            info:"You do not have the permission",
            loading:false,
            dialog:false,
            detailDialog:false,
            toggleOPC:false,
            toggleMQTT:false,
            toggleHubSpec:false,
            toggleHubDetails:false,
            hubHealth:null,
            search:null,
            showSchedule:false,
            headers:[
              {
            text: 'TimeStamp',
            align: 'start',
            //filterable: false,
            value: 'time',
             class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName
            
          },
          { text: 'Relay Type', value: 'relay_type', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
          { text: 'Relay', value: 'relay', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
          //{ text: 'timestamp', value: 'timestamp' },
          { text: 'state', value: 'state', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
          //{ text: 'status', value: 'state' },
          
            ]
        }
    },
    computed:{
        hubID(){
            return this.hub.hub_id
        },
        ssid(){
          if(this.hub['ssid']){
            return this.hub['ssid']
          }else{
            return this.$store.state.organisation['ssid']
          }
        },
        psk(){
          if(this.hub['psk']){
            return this.hub['psk']
          }else{
            return this.$store.state.organisation['psk']
          }
        },
        restartSchedule(){
          if(this.hub.restart_schedule){
            let sched=this.hub.restart_schedule.toString()
            sched=sched.toString().trim().split(' ')
            let schedule=sched[1]+':'+sched[0]+' HRS - '
            switch(sched[4]){
              case '1':
                schedule+="Every Monday"
                break;
              case '2':
                schedule+="Every Tuesday"
                break;
              case '3':
                schedule+="Every Wednesday"
                break;
              case '4':
                schedule+="Every Thursday"
                break;
              case '5':
                schedule+="Every Friday"
                break;
              case '6':
                schedule+="Every Saturday"
                break;
              case '0':
                schedule+="Every Saturday"
                break;
              default:
                schedule+="Everyday"
            }
            return schedule
          }else{
            return 'Not Configured'
          }
        },
        connectionStatus(){
          let status=false
          if(this.hubHealth && this.hubHealth.timestamp && Number(moment().format("X"))-Number(this.hubHealth.timestamp)<=20){
            status=true
          }
          return status
        },
        lastKnownPing(){
          let t=null
          if(this.hubHealth && Number(this.hubHealth.timestamp)){
            t=moment.unix(Number(this.hubHealth.timestamp))
          }
          return t
        },
        relays(){
          let rel=[]
          if(this.hubHealth && this.hubHealth.relay){
            rel=this.hubHealth.relay
            
            for(let i of rel){
              i['time']=new Date(Number(i['timestamp'])*1000)
              i['key']=i['relay_type'].toString()+i['relay'].toString()
            }
          }
          return rel
        },
        relayHealth(){
          let rel=null
          if(this.hubHealth && this.hubHealth.relay_health){
            rel=this.hubHealth.relay_health
          }
          return rel
        }
        
    },
    methods: {
      restart(){
        this.stream.emit('restartHub',this.hub.hub_id)
      },
      update(){
        this.showSchedule=false
        this.$store.dispatch('refreshHubs')
      },
      toggleRelay(relay){
        this.loading=true
        let payload=Object.assign({},relay)
        payload['state']=payload['state']==1?0:1
        payload['hub_id']=this.hub.hub_id
        axios.post(this.$store.state.api+'controlRelay',payload,{headers: {
      Authorization: 'Bearer '+ this.$store.state.jwt
    }}).then(response=>{
      if(response.data.status=='success'){
        this.info='Relay state toggle command sent'
        this.showDismissibleAlert=true
      }else{
        this.info=response.data.msg
        this.showDismissibleAlert=true

      }
      this.loading=false
    })
      },
        deleteHub(){
            if(this.$store.state.user.user_level>this.$store.state.engineerAccessLevel){
               //this.$store.dispatch('deleteMGate',this.MGate)
               this.loading=true
               axios.post(this.$store.state.api+'deleteHub',this.hub,{headers: {
      Authorization: 'Bearer '+ this.$store.state.jwt
    }})
    .then(response=>{
      if(response.data.status==='success'){
          this.$emit('deleteHub',this.hub)
        this.$store.dispatch('refreshHubs')
        this.loading=false
      }else{
        this.info=response.data.msg
        this.showDismissibleAlert=true
        this.loading=false
      }})
      .catch(err=>{console.log(err)})
           }else{
               this.info="You do not have permission"
               this.showDismissibleAlert=true
               this.loading=false
           }
          
        },
          cancel(){
          this.showDialog=false
        },
        confirm(){
          this.showDialog=false
        }
    },
}
</script>