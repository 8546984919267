<template>
    <v-container>
    <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
    <v-form
            ref="viewForm"
            v-model="valid"          
        >
        <v-row>
            <v-col>
            <v-text-field
                        :loading="loading"
                        v-model="form.modbus_latency"
                        :counter="5"
                        :rules="[rules.required]"
                        :label="$store.getters.getTextMap().modbus_ping_latency"
                        required
                        :dark="$store.getters.getColorPalette().isDark" 
                    ></v-text-field>
            <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
        </v-col>
            <v-col>
            <v-text-field
                        :loading="loading"
                        v-model="form.modbus_unhealthy_latency"
                        :counter="5"
                        :rules="[rules.required]"
                        :label="$store.getters.getTextMap().modbus_unhealthy_wait"
                        required
                        :dark="$store.getters.getColorPalette().isDark" 
                    ></v-text-field>
            <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
        </v-col>
            <v-col>
                <v-select
                            v-if="!loading"
                            v-model="form.multiprocessing"
                            :items="boolOptions"
                            :label="$store.getters.getTextMap().fast_mode"
                            dense
                            filled
                            item-text="label"
                            item-value="value"
                            required
                               :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        ></v-select>
            <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
        </v-col>
        </v-row>
        <v-row>
            <v-col align="center">
                        <v-skeleton-loader
                            v-if="loading"
                            class="mx-auto"
                            type="text"
                        ></v-skeleton-loader>
                    <v-btn
                    v-if="!loading"
                       :color="$store.getters.getColorPalette().submitbtnColor"
                       
                        @click="onSubmit"
                    >
                     {{ $store.getters.getTextMap().submit}}
                    </v-btn>
                </v-col>
                <v-col align="center">
                    <v-skeleton-loader
                            v-if="loading"
                            class="mx-auto"
                            type="text"
                        ></v-skeleton-loader>
                    <v-btn
                    v-if="!loading"
                      :color="$store.getters.getColorPalette().cancelbtnColor"
                        :dark="$store.getters.getColorPalette().isDark" 
                        @click="close"
                    >
                    {{ $store.getters.getTextMap().cancel}}
                    </v-btn>
                </v-col>
        </v-row>
    </v-form>
    </v-container>
            
</template>
<script>
import InfoAlert from '@/components/InfoAlert'
import axios from 'axios'
import FormRules from '@/utillities/FormRules'
export default {
    name:'UpdateHubSpec',
    props:['hub'],
    components:{
        InfoAlert
    },
    data(){
        return {
            loading:false,
            showDismissibleAlert:false,
            info:'',
            valid:false,
            form:{
                modbus_latency:0,
                modbus_unhealthy_latency:0,
                multiprocessing:false,
            },
            rules:FormRules.rules,
            boolOptions:[
                {label:this.$store.getters.getTextMap().on,value:true},
                {label:this.$store.getters.getTextMap().off,value:false}
            ],
        }
    },
    mounted(){
        // console.log(this.view,'view_id')
        this.init()

    },
    methods:{
        init(){
            // console.log(this.view)
            if(this.hub && this.hub.hub_id){
                this.form.modbus_latency=this.hub.modbus_latency
                this.form.modbus_unhealthy_latency=this.hub.modbus_unhealthy_latency
                this.form.multiprocessing=this.hub.multiprocessing
        }
        },
        onSubmit(){
            this.$refs.viewForm.validate()
            if(this.valid){
                 this.loading=true
                let d=Object.assign({}, this.form)
                // console.log(d)
                d['hub_id']=this.hub.hub_id
                axios.post(this.$store.state.api+'updateHubOTConfigs',d,{headers: {
                    Authorization: 'Bearer '+ this.$store.state.jwt
                    }})
                    .then(response=>{
                        if(response.data.status=='success'){
                            this.loading=false
                            this.$store.dispatch('refreshHubs')
                            this.$emit('update')
                            this.close()
                        }else{
                            this.loading=false
                            this.info=response.data.msg
                            this.showDismissibleAlert=true
                        }
                    }).catch(err=>{
                        this.loading=false
                        this.info=err
                        this.showDismissibleAlert=true
                        
                    })
            }
        },
        close(){
            this.$emit('close')
        }
    },
    watch:{
        hub:{
            handler(){
                
                this.init()
            },
            deep:true
        },
    }
}
</script>